@use '../variables'as *;

.course-detail {
    display: inline-flex;
    align-items: center;
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: $rounded-full;
        margin-right: $spacing-sm;
    }
    &.long-course {
        color: $color-long-course;
        font-weight: 500;
        &::before {
            background-color: $color-long-course;
        }
    }
    &.short-course {
        color: $color-short-course;
        &::before {
            background-color: $color-short-course;
        }
    }
}

.semester-headers-align .mat-expansion-panel-header-title,
.semester-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.semester-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.semester-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.mat-expanded .mat-expansion-panel-header-title .material-icons{
    transform: rotate(180deg);
  }

.semester-headers-align {
    border-radius: 15px;
    .mat-expansion-panel {
        box-shadow: none;
        background: #F9FAFB;
        border: 0.8px solid #E8EAEF;
        // border-radius: 15px !important;

        .mat-expansion-panel-header {
            border-radius: 15px;
            .mat-expansion-indicator {
                color: $color-black !important;
            }
        }
    }

}

mat-expansion-panel {
    border-radius: inherit;
}

.tb-subject {
    tr {
        td {
            padding: 15px 5px 0px 5px;
            .mat-form-field {
                margin-top: 20px;
                &.num.mat-form-field-appearance-outline {
                    max-width: 100px;
                }
            }
        }
    }
}


// .cdk-global-overlay-wrapper {
//     display: flex;
//     position: absolute;
//     z-index: 1000;
//     overflow: auto;
//     pointer-events: auto;
//   }

