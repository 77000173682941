@use '../variables' as *;
$green: hsl(156deg 55% 31%);
@mixin card {
  background: rgba(35, 122, 87, 0.1);
  border: 0.947826px solid $color-primary;
  border-radius: $rounded-2xl;
}

@mixin btn-credential {
  &:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
  }
  border: 1px solid $color-primary;
}

@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
}

@mixin title {
  font-size: 16px;
  font-weight: bold;
}

.credential-container {
  background: $linear-gradient-primary;
  color: $color-white;
  height: 100vh;
  overflow-x: hidden;

  .col-left {
    float: left;
    width: 50%;
    position: relative;

    height: 100%;
    .hat {
      position: absolute;
      background-image: url(/assets/imgs/convocation_capnew.png);
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-size: 200px auto;
    }
  }
  .credential-form {
    width: 100%;
    background-color: $color-primary;
    z-index: 1;
    @include card();
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .logo {
        width: 89px;
        height: 89px;
        border-radius: 50%;
      }
    }
    .welcome {
      display: flex;
      justify-content: center;
      @include title;
      padding: 20px 0px;
      font-size: 30px;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      mat-form-field {
        input {
          color: $color-black;
        }
      }

      .fg-pwd {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;

        a {
          text-decoration: none;
          color: $color-white;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .btn-credential {
      transition: background 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      @include btn-credential();
      margin: auto;
      height: 60px;
      width: 100%;
      max-width: 300px;
      font-size: 18px;
      &:not(:disabled):hover {
        cursor: pointer;
        background: hsl(0deg 0% 100% / 20%);
      }
    }
    .mat-form-field-infix {
      padding: 1.3em 0em;
    }
    .mat-form-field-outline {
      background-color: white;
      border-radius: $rounded-md;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    .col-left {
      width: 100%;
      position: absolute;
    }
    .credential-form {
      background: rgba($color: $green, $alpha: 0.8);
      width: 95%;
    }
  }
  .col-right {
    position: relative;
    width: 50%;
    float: right;
    height: 100vh;
    background-image: url(/assets/imgs/rectangle.svg);
    background-repeat: no-repeat;

    .person-phone {
      position: absolute;
      top: 50%;
      left: 20%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 400px;
    }

    .merge-img {
      position: absolute;
      top: 60%;
      left: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .globe {
        width: 180px;
        position: absolute;
        left: 103px;
        top: -1px;
      }
      .book {
        width: 175px;
        position: absolute;
        z-index: 1;
        top: 45px;
      }
    }
  }
}

.lamp {
  clear: both;
  margin-left: auto;
  width: 138px;
  height: 100%;
  background-image: url(/assets/imgs/lamp.png);
  background-repeat: no-repeat;
  background-size: 110px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.title {
  @include title();
}

.clearfix:after {
  content: '.';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
