.staff-request-attendance {
  .attachment-wrapper {
    display: flex;
    width: 100%;
    button {
      .mat-button-wrapper {
        display: flex;
        align-items: center;
        .value {
          display: block;
          white-space: break-spaces;
          line-height: normal;
          padding: 6px 0;
        }
      }
      mat-icon {
        min-width: 24px;
      }
    }
  }
}

.record-student-dialog {
  .detail-form {
    .input-icon-suffix {
      .mat-form-field-appearance-outline {
        .mat-form-field-suffix {
          top: 0;
        }
      }
    }
  }
}
