.semester-box {
    position: relative;
    border: 1px solid #E8EAEF;
    border-radius: 10px;
    width: 100%;
    min-height: 167px;
    padding: 20px;

    .title {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .group-btn {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .name {
        color: #63677D;
    }

    .btn-add {
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
    
        transform: translate(-50%, -50%);
        * {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
        &:hover {
            // transform: scale3d(1.1);
            cursor: pointer;
        }
    }
}