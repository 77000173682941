.payment-info {
    padding: 20px;
    .info {
        margin-left: auto;
        display: flex;
        flex-direction: column;
       table {
        border-collapse: collapse;
        tr {
            td, th {
                padding-right: 30px;
                padding-bottom: 20px;
                padding-top: 5px;
            }   
        }
        .hr {
            border-top: 1px solid gray;
        }
        span {
            padding-left: 2px;
        }
       }
    }
}