@use '../variables';

.upload-image-group {
    .input-group {
        flex-wrap: nowrap;
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        .form-outline {
            width: 100%;

            .form-control {
                border: 1px solid;
                min-height: 40px;
                width: 100%;
                padding: 6px 0.75em;
                border-radius: 8px 0 0 8px;
                border-color: #EDF2F9;
                font-size: inherit;
                font-family: inherit;
                background: variables.$background-light-grey;
                color: currentColor;
                outline-color: variables.$color-primary;

                &.invalid {
                    outline-color: #fc7e7e;
                    border: 2px solid #fc7e7e;
                }
            }
        }

        .browse {
            width: 100px;
            min-width: 100px;
            padding: 0 12px;
            font-size: inherit;
            font-family: inherit;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            cursor: pointer;
            border: none;
        }

        .remove-file {
            cursor: pointer;

            .mat-icon {
                position: absolute;
                top: -8px;
                right: -3px;
                background: red;
                color: white;
                border-radius: 50%;
                padding: 2px;
                font-size: 20px;
            }
        }
    }
}

.file-share-detail {
    .view-with-name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}