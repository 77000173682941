@use '../variables' as *;
@use 'sass:map';
@use '../constant';

.form-dialog.class-content {
  mat-form-field {
    .sign {
      display: none;
    }
  }

  .pointer-none {
    pointer-events: none;
  }
}

.override-button {
  .filter-menu {
    position: relative;

    .button-create {
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
    }

    app-filter {
      .create-btn {
        visibility: hidden;
      }
    }
  }
}

.option-group {
  .mat-optgroup-label {
    background-image: map-get($map: constant.$linear-gradient-colors, $key: 'light-gradient-primary');
  }
}

.class-subject {
  .dot {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    min-height: 10px;
    border-radius: 50%;
    border: 1px solid $color-thin-grey;
  }
}

.class-attendance {
  .detail-form {
    .filter-search {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field {
        &.mat-form-field-appearance-outline {
          .mat-form-field-outline-start,
          .mat-form-field-outline-end {
            background-color: white;
          }
        }
      }
    }
  }

  .table-container {
    &.student-attendance {
      thead {
        tr {
          &:last-child {
            background-color: #fff;
          }
        }
      }

      tbody {
        tr {
          &.mat-row {
            &:nth-child(odd) {
              background-color: #f5f5f5;
            }
            &:nth-child(even) {
              background-color: #fff;
            }
          }
        }
      }
    }

    &.promote-student {
      .mat-radio-disabled {
        pointer-events: none;
      }
    }
  }

  mat-divider {
    border-top-color: $athens-gray-color;
  }

  mat-button-toggle-group {
    border: none;
    border-radius: 0;

    mat-button-toggle {
      border: 1px solid $border-grey;
      border-radius: 8px;

      button {
        min-width: 148px;
        height: 28px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .mat-button-toggle-label-content {
          line-height: 0;

          .label {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              width: 8px;
              height: 8px;
              display: block;
              border-radius: 100%;
              margin-right: 12px;
              border: 1px solid $color-grey;
            }
          }
        }
      }
    }

    .mat-button-toggle-checked.present {
      background-color: $salem;
    }

    .mat-button-toggle-checked.absent {
      background-color: $bittersweet;
    }

    .mat-button-toggle-checked.none {
      background-color: #fff;
    }

    .mat-button-toggle-checked.permit {
      background-color: $deluge;

      .permitted {
        position: absolute;
        right: -22px;
        top: -8px;
        width: 18px;
      }
    }

    .mat-button-toggle-checked.present,
    .mat-button-toggle-checked.absent,
    .mat-button-toggle-checked.permit {
      .label {
        color: #fff;

        span {
          background-color: #fff;
          border: none;
        }
      }
    }
  }

  .vertical {
    nav {
      .mat-tab-links {
        column-gap: 16px;
        .nav-wrapper {
          column-gap: 0;
          .nav-card {
            a {
              .mat-ripple-element {
                display: none;
              }
            }
          }

          .button-wrapper {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 0.3s, opacity 0.3s linear;

            &.visible {
              visibility: visible;
              opacity: 1;
              transition: opacity 0.3s linear;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1185px) {
    .vertical {
      display: flex;
      flex-direction: row;

      nav {
        .mat-ink-bar {
          display: none;
        }

        .mat-tab-links {
          flex-direction: column;
          width: 100%;

          .nav-wrapper {
            position: relative;

            &.nav-active {
              background-color: $alabaster;

              .nav-card {
                border-radius: 4px;
                a {
                  opacity: 1;
                }
              }
            }

            .justify-between {
              justify-content: space-between;
            }

            .nav-card {
              column-gap: 16px;
              padding: 0 24px;
              a {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                height: 65px;
                row-gap: 4px;
                border: none;
                font-weight: bold;
                width: 100%;
                padding: 0;
                text-decoration: none;
                color: $scarpa-flow;

                span {
                  color: $silver-chalice;
                  font-weight: normal;
                }
              }
            }

            .mat-tab-label-active {
              opacity: 1;
              border-radius: 4px;
              border: none;
            }

            .border-active {
              position: absolute;
              right: 0;
              top: 0;
              width: 3px;
              height: 100%;
              background-color: $color-primary;
            }
          }
        }
      }

      .table-wrapper {
        flex: 1;
        padding-left: 32px;
        border-left: 1px solid rgba($color: $color-primary, $alpha: 0.1);

        .label-status {
          &.present {
            color: $salem;
          }
          &.absent {
            color: $bittersweet;
          }
          &.permission {
            color: $deluge;
          }
        }
      }

      .mat-tab-header {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .line-breaker {
      width: auto;
      display: flex;
      align-items: center;

      span {
        width: 2px;
        height: 16px;
        background-color: $athens-gray-color;
        display: block;
        margin: 0 30px;
      }
    }
  }

  // @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
  //   mat-button-toggle-group {
  //     display: flex;
  //     flex-direction: column;

  //     mat-button-toggle {
  //       margin-bottom: 8px;
  //     }
  //   }
  // }
}

.class-attendance-dialog {
  .name {
    color: $color-primary;
  }

  td {
    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        margin-right: 12px;
      }
    }

    // .present {
    //   span {
    //     background-color: $salem;
    //   }
    // }

    // .absent {
    //   span {
    //     background-color: $bittersweet;
    //   }
    // }

    // .permit {
    //   span {
    //     background-color: $deluge;
    //   }
    // }
  }
}

.class-mat-menu + * .cdk-overlay-pane {
  .mat-menu-panel {
    p {
      margin-bottom: 0;
      color: $aluminium;
      padding-inline: 16px;
    }
  }
}

.class-grading {
  .mat-table {
    tr {
      &.mat-row {
        height: 60px;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-subscript-wrapper {
        display: none;
      }
    }

    .error-max-score {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          border: 2px solid $color-light-red;
        }

        .mat-form-field-outline-start {
          border-right: none;
        }

        .mat-form-field-outline-end {
          border-left: none;
        }
      }
    }

    .warn-saving {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          border: 2px solid $color-warn;
        }

        .mat-form-field-outline-start {
          border-right: none;
        }

        .mat-form-field-outline-end {
          border-left: none;
        }
      }.mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          border: 2px solid $color-warn;
        }

        .mat-form-field-outline-start {
          border-right: none;
        }

        .mat-form-field-outline-end {
          border-left: none;
        }
      }
    }

    .error-text {
      color: $color-light-red;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .mat-select-width {
      max-width: 120px;

      .mat-mdc-form-field {
        &.mat-form-field-appearance-outline {
          cursor: pointer;

          .mat-mdc-text-field-wrapper {
            margin: 0;
            padding: 0;

            .mat-mdc-form-field-infix {
              padding: 0;
              min-height: 30px;
              width: 30px;

              .mat-mdc-select-arrow-wrapper {
                display: none;
              }
            }

            .mat-mdc-form-field-icon-suffix {
              padding: 0;
            }

            .mat-mdc-form-field-flex {
              .mdc-notched-outline {

                &__leading,
                &__trailing,
                &__notch {
                  border-color: transparent !important;
                }
              }
            }
          }
        }

        .mdc-text-field--disabled {
          .mdc-notched-outline {
            &__leading {
              background-color: transparent;
            }

            &__trailing {
              background-color: transparent;
            }
          }
        }
      }

      mat-icon {
        padding: 0 !important;
      }
    }
  }
}

.pass-fail-select {
  min-width: 92px !important;
}
