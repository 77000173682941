@use '../variables' as *;
@import "~angular-calendar/css/angular-calendar.css";

//*Faculty Detail's Styles
.faculty-detail,
.department-detail,
.building-detail,
.degree-detail,
.subject-detail {
  .sub-title {
    color: $color-primary;
    font-size: 18px;
    font-weight: 600;
  }

  .title {
    align-items: center;
  }

  .flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
  }

  ._content {
    width: 100%;
    max-width: 36em;
  }

  .break-line {
    width: 100%;
    height: 1px;
    background-color: $color-thin-grey;
  }

  .t-header-action {
    align-items: center;

    .create-button {
      margin-left: auto;
    }
  }

  .group-btn {
    margin-left: auto;

    button {
      &:not(:last-child) {
        margin-right: $spacing-sm;
      }
    }
  }
}

//TODO: wrap long text in table
table {
  .group-btn {
    button {
      margin-top: 5px;

      &:last-child {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
    td.wrap-text {
      word-wrap: break-word;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

//*Department Detail's Styles
.department-dialog {
  .mat-chip-list {
    .mat-chip {
      margin: 0 3px;
    }
  }

  mat-form-field {
    position: relative;

    .mat-chip-error {
      position: absolute;
      bottom: -22px;
      color: #fc7e7e;
      font-size: 11px;
    }
  }

  .chip-list-error {
    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      opacity: 1;
      color: #fc7e7e !important;
    }
  }
}


//TODO: make text in mat chip list smaller
.autocomplete-list {
  mat-option {
    .mat-option-text {
      font-size: 14px;
    }
  }
}

//* Subject's Styles
.table-subject {
  .color-box {
    width: 30px;
    height: 30px;
    display: block;
    border-radius: $rounded-sm;
    box-shadow: 1px 1px 5px #c1c1c1;
  }
}

//* Custom Input Color
.subject-dialog {
  .color-box {
    min-width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: $rounded-sm;
    box-shadow: 1px 1px 5px #c1c1c1;
    margin-right: $spacing-md;
  }

  mat-form-field {
    .mat-form-field-infix {
      .input-color {
        display: flex;
        justify-content: space-between;

        .hex {
          display: flex;
          justify-content: flex-start;
        }

        button {
          .pick-color {
            cursor: pointer;
            position: absolute;
            top: 0;
            visibility: hidden;
          }

          position: relative;
          margin-left: auto;

          mat-icon {
            transform: translateY(-7px);
          }
        }

        .mat-icon-button {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.subject-dialog,
.department-dialog,
.degree-dialog,
.faculty-dialog,
.calendar-dialog,
.form-dialog {
  form {
    label.required {
      span {
        color: $color-required;
      }
    }
  }
}

.color-list {
  &__item {
    width: 28px;
    height: 28px;
    border-radius: $rounded-sm;
    box-shadow: 1px 1px 5px #c1c1c1;

    &--selected {
      position: absolute;
      left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}