
.grade-scale {
  .grade-scale-manage {
    color: #FFFFFF;
    padding: 2px 16px;
    margin-left: 3em;
  }
}

.manage-grade-scale {
  padding: 0;
  box-shadow: none;
  .table-custom tr th, .table-custom tr th.mat-header-cell {
    border: none;
  }
  .table-custom tr td {
    border: none !important;
    background-color: #FFFFFF;
  }
  .form-control {
    width: 180px;
  }
}
form {
  display: block;
  margin-top: 0em;
}
