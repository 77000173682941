@use '../variables' as *;

$rounded: 16;
$black: #000000;

.mat-table {
  tr {
    &.mat-row {
      &:nth-child(even) {
        // background-color: #f5f5f5;
      }
    }
  }

  &.table-custom {
    tr {
      &.mat-row {
        &:nth-child(even) {
          background-color: unset;
        }
      }
    }
  }

  .group-picture-name {
    display: flex;
    align-items: center;

    .profile-with-badge {
      position: relative;
      display: flex;
      align-items: center;
      margin: 8px 8px 8px 0;

      img {
        &.badge-icon {
          position: absolute;
          width: 15px;
          height: 15px;
          right: -2px;
          bottom: -2px;
          background: #fff;
          border-radius: 50%;
          padding: 2px;
          box-shadow: 0 0 3px 0 $color-grey;
        }

        &.profile-picture-list {
          margin: 0;
        }
      }
    }
  }

  .status {
    display: inline-flex;
    align-items: center;
    padding: $spacing-xs $spacing-md;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: $rounded-full;
      margin-right: $spacing-sm;
    }

    &.active,
    &.default,
    &.added,
    &.passed,
    &.successfully,
    &.verify,
    &.verified,
    &.approved,
    &.published,
    &.completed,
    &.finish_study {
      color: $salem;
      background-color: rgba($color: $salem, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $salem;
      }
    }

    &.pending,
    &.requesting,
    &.incomplete,
    &.pretest,
    &.qualification_test,
    &.practice,
    &.payment,
    &.progress,
    &.requesting {
      color: $color-warn;
      background-color: rgba($color: $color-warn, $alpha: 0.2);

      &::before {
        background-color: $color-warn;
      }
    }

    &.inactive,
    &.failed,
    &.unsuccessfully,
    &.rejected,
    &.expired,
    &.disabled,
    &.unpublished,
    &.quit,
    &.delete_name,
    &.quit_before_course,
    &.quit_during_course {
      color: $color-red;
      background-color: rgba($color: $color-red, $alpha: 0.2);

      &::before {
        background-color: $color-red;
      }
    }

    &.empty,
    &.cancelled,
    &.none,
    &.completed {
      color: $color-black;
      background-color: rgba($color: $color-grey, $alpha: 0.2);

      &::before {
        background-color: $color-grey;
      }
    }
  }

  .status-fill {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: $rounded-full;
      margin-right: $spacing-sm;
    }

    &.active,
    &.passed,
    &.approved,
    &.completed,
    &.finish_study {
      color: $salem;
      padding: 8px 18px;
      background-color: rgba($color: $salem, $alpha: 0.1);
      border-radius: 50px !important;

      &::before {
        background-color: $salem;
      }
    }

    &.pending,
    &.incomplete,
    &.pretest,
    &.qualification_test,
    &.practice,
    &.payment,
    &.progress,
    &.requesting {
      color: $sea-buckthorn;
      background-color: rgba($color: $sea-buckthorn, $alpha: 0.1);
      padding: 8px 18px;
      border-radius: 50px !important;

      &::before {
        background-color: $sea-buckthorn;
      }
    }

    &.inactive,
    &.failed,
    &.rejected,
    &.quit,
    &.quit_before_course,
    &.quit_during_course {
      color: $color-light-red;
      padding: 8px 18px;
      background-color: rgba($color: $color-light-red, $alpha: 0.1);
      border-radius: 50px !important;

      &::before {
        background-color: $color-light-red;
      }
    }

    &.empty,
    &.canceled,
    &.none {
      color: $scarpa-flow;
      padding: 8px 18px;
      background-color: rgba($color: $scarpa-flow, $alpha: 0.1);
      border-radius: 50px !important;

      &::before {
        background-color: #838383;
      }
    }

    &.draft {
      color: #0093E9 ;
      padding: 8px 18px;
      background-color: rgba($color: #0093E9, $alpha: 0.1) ;
      border-radius: 50px !important;

      &::before {
        background-color: #0093E9 ;
      }
    }
  }

  .course {
    display: inline-flex;
    align-items: center;
    padding: $spacing-xs $spacing-md;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: $rounded-full;
      margin-right: $spacing-sm;
    }

    &.long-course {
      color: $color-long-course;
      background-color: rgba($color: $color-long-course, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-long-course;
      }
    }

    &.short-course {
      color: $color-short-course;
      background-color: rgba($color: $color-short-course, $alpha: 0.2);

      &::before {
        background-color: $color-short-course;
      }
    }
  }

  .action {
    display: inline-flex;
    align-items: center;
    padding: $spacing-xs $spacing-md;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: $rounded-full;
      margin-right: $spacing-sm;
    }

    &.update {
      color: $color-secondary;
      background-color: rgba($color: $color-secondary, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-secondary;
      }
    }

    &.delete {
      color: $color-red;
      background-color: rgba($color: $color-red, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-red;
      }
    }

    &.create {
      color: $color-primary;
      background-color: rgba($color: $color-primary, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-primary;
      }
    }

    &.change_password {
      color: $color-long-course;
      background-color: rgba($color: $color-long-course, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-long-course;
      }
    }

    &.reset_password {
      color: $color-light-red;
      background-color: rgba($color: $color-light-red, $alpha: 0.2);
      font-weight: 500;

      &::before {
        background-color: $color-light-red;
      }
    }

    &.login {
      color: $color-short-course;
      background-color: rgba($color: $color-short-course, $alpha: 0.2);

      &::before {
        background-color: $color-short-course;
      }
    }
  }

  .el-responsive {
    min-width: 100px;
    @include line-clamp(2);
  }

  .group-btn {
    width: max-content;
    margin-left: 0;
  }
}

.table-container {
  border-radius: $rounded-md;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .progress-bar {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
  }

  .mat-progress-bar-fill::after {
    background-color: #226a5f !important;
  }
}

.ellipse-txt {
  max-width: 494px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* width */
.table-container::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 100px;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
