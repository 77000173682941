@use '../variables';

.creation-form,
.detail-form {
  .header-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 18px;
    font-weight: 600;
  }

  .sub-header-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 16px;
    font-weight: 600;
  }

  .group-action {
    text-align-last: end;
  }

  .value {
    color: variables.$color-black;
    font-weight: 600;

    &.name {
      font-size: 18px;

      .position {
        color: variables.$color-primary;
        font-weight: 500;
      }
    }
    &.profile-image {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 6px;
        margin: 8px 8px 8px 0;
      }
    }
  }

  .phone-number {
    display: flex;
    align-items: center;

    svg {
      vertical-align: top;
    }
  }

  .profile-with-name {
    display: flex;
    align-items: center;
  }

  .right-side {

    .resume-file,
    .cv {
      display: flex;
      align-items: center;
    }

    
  }

  .invalid {
    color: variables.$color-required;
  }

  .required::after {
    content: '*';
    color: variables.$color-required;
    vertical-align: middle;
    margin-left: 4px;
  }

  @media screen and (min-width: 1280px) {
    .left-side {
      padding-right: variables.$spacing-lg;
      border-right-width: 1px;
      border-right-color: rgba(0, 0, 0, 0.05);
    }

    .right-side {
      padding-left: variables.$spacing-md;

      .resume-file {
        .download-btn {
          margin-left: auto;
        }
      }

      .header-label {
        display: block !important;
        //visibility: hidden;
      }
    }
  }
}

.readonly-form {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: transparent;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      ;
    }
  }
}

.required-form {
  label {
    span {
      color: variables.$color-required;
    }
  }
}

.profile_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  height: 100px;

  background: #F9F9F9;
  border: 1px dashed #E8E9ED;
  border-radius: 10px;
  .add_user {
    width: 50px;
    height: 50px;
  }
  p {
    color: #E8E9ED;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
  }
  &:hover {
    cursor: pointer;
  }
  .close {
    position: absolute;
    right: -10px;
    top: -10px;
    display: none;
  }
  &.image:hover {
    .close {
      display: block;
      cursor: pointer;
    }
  }
}