@use 'sass:map';
@use '../constant';
@use '../variables';

.app-menu {
  position: relative;

  .menu-hamburger {
    background-color: white;
    border-radius: 0 variables.$rounded-sm variables.$rounded-sm 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .dashboard-toolbar {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: absolute;
    padding: 0;
    height: auto;
    width: 100%;

    .dashboard__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0.5rem 30px 0.5rem 0;
      min-height: 70px;
      width: 100%;
      opacity: 0.97;
      z-index: 1;

      h1 {
        margin: 0;
        padding: 0;
        font-weight: 400;
        flex: 1;
        margin-left: 32px;
      }

      .icon {
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .header-action {
      display: flex;

      .action {
        display: grid;
        grid-template-columns: repeat(2, [col] 40px);
        gap: 16px;

        .item {
          &.vertical-line {
            width: 1px;
            background-color: #ffffff;
          }
        }
      }

      .avtar {
        display: flex;

        .avtar-icon {
          border-radius: 6px;
          width: 40px;
          height: 40px;
          background-size: cover;
          object-fit: cover;
          background-color: rgba($color: #000000, $alpha: 0.16);
          // margin-left: 10px;
        }
      }
    }

    .dash-row {
      display: block;
      width: 100%;
      overflow: auto;
    }

    .dash-row+.dash-row {
      margin: 0rem 1rem;
    }
  }

  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .mat-dialog-container {
    background: #f8f9fe;
    padding: 50px 80px !important;

    .mat-dialog-actions {
      button.mat-focus-indicator.mat-button.mat-button-base.mat-primary {
        color: #59595b;
      }

      button {
        background: #fff;
        padding: 5px 25px;
      }
    }
  }

  .menu-sidenav .mat-drawer-inner-container {
    overflow: auto;
    direction: rtl;
  }

  .app-container {
    height: 100vh;

    // @media screen and (min-width: map-get($map: variables.$grid-breakpoints, $key: 'md')) {
    //   padding-left: variables.$layout-gap;
    // }

    background: #f5f5f5;

    .menu-sidenav {
      border: none;

      // @media screen and (max-width: map-get($map: variables.$grid-breakpoints, $key: 'md')) {
      //   margin-left: variables.$layout-gap;
      // }

      /* width */
      ::-webkit-scrollbar {
        width: 1em !important;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: variables.$color-primary !important;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #fff !important;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #eee !important;
      }
    }
  }

  .mat-list-base .mat-list-item.mat-list-item-with-avatar,
  .mat-list-base .mat-list-option.mat-list-item-with-avatar {
    height: 55px !important;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .mat-nav-list#list-nav {
    direction: ltr;

    span,
    mat-icon,
    .mat-expansion-indicator::after {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
      color: #ffffff;
      opacity: 0.85;
      font-weight: normal;
    }

    .active {

      span,
      mat-icon,
      .mat-expansion-indicator::after {
        opacity: 1;
        font-weight: 600;
      }
    }

    mat-list-item {
      .mat-list-item-content {
        width: 100%;
      }
    }

    //*Custom child nav style
    .mat-expansion-panel .mat-expansion-panel-body .expand {
      span {
        display: flex;
      }

      .mat-list-item-content {
        margin-left: 1em;
      }
    }

    .mat-list-text {
      display: none !important;
    }

    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
      background: inherit;
    }
  }
}

.nav-mat-menu + * .cdk-overlay-pane {
  margin-left: 6em;

  .mat-menu-panel {
    overflow-x: hidden;
    border-radius: variables.$rounded-sm;
    mat-nav-list {
      padding-top: 0;
      .active {
        background-color: rgba($color: variables.$color-primary, $alpha: 1);
        .menu-item-title {
          color: #fff;
        }
      }
      .menu-item-title {
        font-size: 14px;
        border-radius: variables.$rounded-md;
      }
    }
  }
}

.hover-profile + * .cdk-overlay-pane {
  .mat-menu-panel {
    border-radius: variables.$rounded-sm;

    .profile-img {
      display: flex;
      justify-content: flex-start;
      width: 17em;
      align-items: center;
      border-radius: initial;
      height: 70px;
      min-height: 70px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: transparent !important;
      }

      .avatar-profile {
        width: 35px;
        height: 35px;
      }

      .name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        font-weight: 600;
        letter-spacing: 1.3px;
      }
      .role {
        font-weight: lighter;
        font-size: 12.5px;
      }

      &:hover {
        background-color: variables.$black-squeeze-color;
      }
    }
  }
}
